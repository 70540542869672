<template>
  <v-select
    placeholder="Chọn kho"
    v-model="inventoryInfo"
    label="name"
    :clearable="false"
    :filterable="false"
    :options="inventories"
    :disabled="isDisabled"
    @input="handleChangeInventory"
    @search="handleDebounce"
  >
    <template #open-indicator="{ attributes }">
      <span v-bind="attributes">
        <feather-icon icon="ChevronDownIcon" size="20" />
      </span>
    </template>
  </v-select>
</template>
<script>
import vSelect from "vue-select";
import appUtils from "../../utils/appUtils";

export default {
  name: "SelectInventory",
  props: { isDisabled: Boolean },
  components: { vSelect },
  data() {
    return {
      inventoryInfo: {},
      inventories: []
    };
  },
  async created() {
    const response = await this.fetchPharmaInventories();

    const inventoryLocalStorage = JSON.parse(
      localStorage.getItem("inventoryInfo")
    );

    if (inventoryLocalStorage) {
      this.inventoryInfo = inventoryLocalStorage;
    } else {
      this.inventoryInfo = response?.length > 0 ? response[0] : null;
    }

    this.$emit("onChangeInventory", this.inventoryInfo);
  },
  methods: {
    handleChangeInventory(inventory) {
      localStorage.setItem("inventoryInfo", JSON.stringify(inventory));
      this.$emit("onChangeInventory", inventory);
    },
    async fetchPharmaInventories(search) {
      const params = {
        keyword: search,
        page_size: 1000,
        page_num: 1,
        org_id: this.$pharmacyInfo.id
      };

      try {
        return this.$store
          .dispatch("inventoryManager/fetchInventories", params)
          .then(response => {
            this.inventories = response.data?.data || [];
            return this.inventories;
          });
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi khi lấy dữ liệu kho",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      }
    },
    handleDebounce: appUtils.debounce(function(search) {
      this.fetchPharmaInventories(search);
    }, 800)
  }
};
</script>

<style lang="scss" scoped>
</style>