<template>
  <div>
    <div class="row mb-2">
      <div class="col-8">
        <div class="row">
          <div class="col-6">
            <div>
              <label>Hiển thị</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>mục</label>
            </div>
          </div>
          <div class="col-6">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Tìm kiếm..."
              @update="handleDebounce"
            />
          </div>
        </div>
      </div>
    </div>
    <b-overlay
      :show="isLoading"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
    >
      <b-table
        class="position-relative"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Không có dữ liệu hiển thị"
      >
        <template #cell(product_code)="data">
          <span class="text-capitalize">{{` ${data.item.product_code}` }}</span>
        </template>
        <template #cell(name)="data">
          <span class="text-capitalize">{{data.item.name }}</span>
        </template>
        <template #cell(expired_date)="data">
          <span class="text-capitalize">{{formatExpiredDate(data.item) }}</span>
        </template>
        <template #cell(active_ingredient)="data">
          <span class="text-capitalize">{{getActiveIngredient(data.item) }}</span>
        </template>
        <template #cell(price_in)="data">
          <span>{{ appUtils.numberFormat(data.item.price_in) }}</span>
        </template>
        <template #cell(price_out)="data">
          <span>{{ appUtils.numberFormat(data.item.price_out) }}</span>
        </template>
        <template #cell(vat_in)="data">
          <span>{{`${data.item.vat_in}%` }}</span>
        </template>
        <template #cell(vat_out)="data">
          <span>{{`${data.item.vat_out}%` }}</span>
        </template>
        <template #cell(registration_number)="data">
          <span class="text-capitalize">{{(data.item.registration_number) }}</span>
        </template>
        <template #cell(lot_number)="data">
          <span class="text-capitalize">{{(data.item.lot_number) }}</span>
        </template>
        <template #cell(supplier)="data">
          <span class="text-capitalize">{{getSupplierLabel(data.item) }}</span>
        </template>
        <template #cell(amount)="data">
          <span class="text-capitalize">{{(data.item.qty) }}</span>
        </template>
      </b-table>
    </b-overlay>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">
            Hiển thị {{ dataMeta.from }} đến {{ dataMeta.to }} của {{ dataMeta.of }}
            mục
          </span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProducts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
  <script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import appUtils from "../../utils/appUtils";
import vSelect from "vue-select";
import SelectInventory from "@/components/SelectInventory/index.vue";

const tableColumns = [
  {
    key: "product_code",
    label: "Mã thuốc",
    sortable: false
  },
  {
    key: "name",
    label: "Tên thuốc",
    sortable: false
  },
  { key: "active_ingredient_info.name", label: "Hoạt chất", sortable: false },
  { key: "unit_info.name", label: "Đơn vị", sortable: false },
  { key: "amount", label: "Tồn kho", sortable: false },
  { key: "lot_number", label: "Số lô", sortable: false },
  {
    key: "registration_number",
    label: "SĐK",
    sortable: false
  },
  { key: "price_in", label: "Giá nhập", sortable: false },
  { key: "price_out", label: "Giá nhập", sortable: false },
  { key: "vat_in", label: "VAT nhập(%)", sortable: false },
  { key: "vat_out", label: "VAT nhập(%)", sortable: false },
  { key: "expired_date", label: "Hạn sử dụng", sortable: false }
  //   {
  //     key: "supplier",
  //     label: "Nhà cung cấp",
  //     sortable: false,
  //     thStyle: { width: "20%" }
  //   }
];

export default {
  name: "ProductInventoryDetail",
  components: { vSelect, SelectInventory },
  data() {
    return {
      isLoading: false,
      dataList: [],
      currentPage: 1,
      perPage: 15,
      totalProducts: 0,
      searchQuery: "",
      inventoryInfo: null,
      tableColumns,
      appUtils,
      perPageOptions: [10, 15, 25, 50]
    };
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.dataList?.length || 0;
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalProducts
      };
    }
  },
  watch: {
    currentPage() {
      this.handleGetListData();
    },
    perPage() {
      this.handleGetListData();
    }
  },
  async created() {
    this.handleGetListData();
  },

  methods: {
    handleDebounce: appUtils.debounce(function() {
      this.handleGetListData();
    }, 1000),
    async handleGetListData() {
      if (!this.$route.params?.master_id || !this.$route.query?.inventory_id)
        return;

      try {
        const params = {
          master_id: this.$route.params?.master_id,
          inventory_id: this.$route.query?.inventory_id,
          pharma_id: this.$pharmacyInfo.id,
          keyword: this.searchQuery,
          page_num: this.currentPage,
          page_size: this.perPage,
          sort_by: "id",
          order: "desc"
        };

        const response = await this.$store.dispatch(
          "phamarProduct/getPharmaProducts",
          params
        );

        this.dataList = response.data?.data || [];
        this.totalProducts = response.data.page?.total;
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Có lỗi xảy ra khi lấy dữ liệu",
            icon: "AlertTriangleIcon",
            variant: "danger"
          }
        });
      }
    },
    formatDateTime(date) {
      return window.moment(date).format("HH:mm DD/MM/YYYY");
    },
    getSupplierLabel(data) {
      return data?.supplier_info?.supplier_name;
    },
    getActiveIngredient(data) {
      return data?.product_info?.active_ingredient_info?.name || "";
    },
    getUnit(data) {
      return data?.product_info?.unit_info?.name || "";
    },
    getSupplierLabel(data) {
      return data?.product_info?.supplier_info?.supplier_name || "";
    },
    formatExpiredDate(data) {
      const date =
        Number(data?.expiry_date_day) && Number(data?.expiry_date_day) < 10
          ? `0${data?.expiry_date_day}/`
          : data?.expiry_date_day
          ? `${data?.expiry_date_day}/`
          : "";

      const month =
        Number(data?.expiry_date_month) && Number(data?.expiry_date_month) < 10
          ? `0${data?.expiry_date_month}/`
          : data?.expiry_date_month
          ? `${data?.expiry_date_month}/`
          : "";
      return `${date}${month}${data?.expiry_date_year || ""}`;
    }
  }
};
</script>
    
    <style lang="scss" scoped>
</style>